import {Link} from "react-scroll";

export const Footer = () => {
    return (
        <>
            {/* ======= Footer ======= */}
            <footer id="footer">
                {/*
                <div className="footer-newsletter">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <h4>Join Our Newsletter</h4>
                                <p>
                                    Tamen quem nulla quae legam multos aute sint culpa legam noster
                                    magna
                                </p>
                                <form action="" method="post">
                                    <input type="email" name="email"/>
                                    <input type="submit" defaultValue="Subscribe"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                <div className="footer-top bg-[#f1f6fe]">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3>
                                    🚀 <span>Rocketize</span> Web
                                </h3>
                                <p>
                                    Myraveien 31 <br/>
                                    3425, Reistad
                                    <br/>
                                    Norway <br/>
                                    <br/>
                                    <strong>Phone:</strong> +47 406 40 382
                                    <br/>
                                    <strong>Email:</strong> contact@rocketizeweb.com
                                    <br/>
                                </p>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <i className="bx bx-chevron-right"/>
                                        <Link className="nav-link scrollto cursor-pointer" activeClass={"active"} smooth
                                              spy
                                              to="hero" offset={-200} duration={200}>
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="about"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            About
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>
                                        <Link className="nav-link scrollto cursor-pointer" activeClass={"active"} smooth
                                              spy
                                              to="services" offset={-69} duration={200}>
                                            Services
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="contact"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li>
                                        <i className="bx bx-chevron-right"/>
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="services"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            Web Design
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>{" "}
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="services"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            Web Development
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>{" "}
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="services"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            SEO
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="services"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            Marketing
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>{" "}
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="services"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            Analytics
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bx bx-chevron-right"/>{" "}
                                        <Link className="nav-link scrollto cursor-pointer"
                                              activeClass={"active"}
                                              to="services"
                                              offset={-69}
                                              duration={200}
                                              smooth
                                              spy>
                                            A/B Testing
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>About us</h4>
                                <p>
                                    Rocketize Web is a norwegian company helping businesses enhance their online
                                    presence.
                                </p>
                                {/*
                                <div className="social-links mt-3">
                                    <a href="#" className="twitter">
                                        <i className="bx bxl-twitter"/>
                                    </a>
                                    <a href="#" className="facebook">
                                        <i className="bx bxl-facebook"/>
                                    </a>
                                    <a href="#" className="instagram">
                                        <i className="bx bxl-instagram"/>
                                    </a>
                                    <a href="#" className="google-plus">
                                        <i className="bx bxl-skype"/>
                                    </a>
                                    <a href="#" className="linkedin">
                                        <i className="bx bxl-linkedin"/>
                                    </a>
                                </div>
                                */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-4">
                    <div className="copyright">
                        © Copyright{" "}
                        <strong>
                            <span>Rocketize Web</span>
                        </strong>
                        . All Rights Reserved
                    </div>
                </div>
            </footer>
            {/* End Footer */}
        </>

    )
}