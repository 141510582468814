import {Link} from "react-scroll";

interface MainButtonProps {
    className?: string;
    children?: React.ReactNode;
    href: string;
}

export const MainButton = ({className, children, href}:MainButtonProps) => {

    return (
        <Link className={"scrollto text-sm font-medium font-[roboto] bg-main text-white px-4 py-2.5 uppercase rounded hover:bg-blue-800 transition-all cursor-pointer " + className} to={href} offset={-69} smooth={true} duration={300}>
            {children}
        </Link>
    )
}