import {Collapsable} from "../../../components/Collapsable";
import {Link} from "react-scroll";

export const FAQSection = () => {
    return (
        <>
            {/* ======= Frequently Asked Questions Section ======= */}
            <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>F.A.Q</h2>
                        <h3>
                            Frequently Asked <span>Questions</span>
                        </h3>
                        <p>
                            Have questions? We have answers! Browse our FAQs for instant insights. If you don't find
                            what you're looking for, feel free to <Link className={"text-main cursor-pointer hover:text-blue-800 transition-all"} to={"contact"} offset={-69} smooth={true} duration={300}>book a meeting</Link> with us.
                        </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div id="accordion-collapse" data-accordion="collapse">
                                <Collapsable id={"1"} label={"What services does Rocketize offer?"}>
                                    Rocketize Web specializes in creating custom websites tailored to the unique needs
                                    of your business. We also provide comprehensive Facebook marketing services,
                                    including ad campaign management, page optimization, and analytics tracking to help
                                    increase your online presence and engagement.
                                </Collapsable>
                                <Collapsable id={"2"} label={"How does the process of building a website work?"}>
                                    Our website design process is client-focused and involves several key steps:
                                    <ul>
                                        <li>
                                            <span className={"font-bold"}>Initial Consultation: </span>
                                            Discuss your business goals, website needs, and design preferences.
                                        </li>
                                        <li>
                                            <span className={"font-bold"}>Proposal & Agreement: </span>
                                            Outline of the project scope, timeline, and costs.
                                        </li>
                                        <li>
                                            <span className={"font-bold"}>Design & Development: </span>
                                            Development of the website.
                                        </li>
                                        <li>
                                            <span className={"font-bold"}>Review & Feedback: </span>
                                            Opportunities for you to review the work and provide feedback.
                                        </li>
                                        <li>
                                            <span className={"font-bold"}>Launch & Training: </span>
                                            Final adjustments, website launch, and training on how to manage your new
                                            site.
                                        </li>
                                    </ul>
                                </Collapsable>
                                <Collapsable id={"3"} label={"Can Rocketize redesign our existing website?"}>
                                    Yes, we can redesign existing websites to enhance functionality, improve user
                                    experience, update aesthetics, and optimize performance for better SEO and user
                                    engagement.
                                </Collapsable>
                                <Collapsable id={"4"} label={"How long does it take to build a website?"}>
                                    The time to build a website can vary depending on its complexity and the specifics
                                    of your project. Typically, a standard website takes about 4-6 weeks from start to
                                    finish, while more complex projects might take longer.
                                </Collapsable>
                                <Collapsable id={"5"} label={"How much does a website cost?"}>
                                    The cost of a website varies based on its complexity, features, and design
                                    requirements. We provide a detailed quote after our initial consultation to
                                    assess your specific needs.
                                </Collapsable>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Frequently Asked Questions Section */}
        </>

    )
}