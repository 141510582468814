import {useEffect, useState} from "react";
import {Link} from "react-scroll"

export const Header = () => {
    const [fixed, setFixed] = useState<boolean>(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 40) {
                setFixed(true)
            } else {
                setFixed(false)
            }
        }

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, {passive: true});
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            {/* ======= Header ======= */}
            <div className={"h-20 " + (fixed ? "" : "hidden")}>

            </div>
            <header id="header" className={"d-flex align-items-center " + (fixed ? "fixed-top" : "")}>
                <div className="container d-flex align-items-center justify-content-between">
                    <h1 className="logo"><a href="/">🚀 <span>Rocketize</span> Web</a></h1>
                    {/* Uncomment below if you prefer to use an image logo */}
                    <a href="index.html" className="logo"><img src="/img/logo.png" alt=""/></a>
                    <nav id="navbar" className="navbar">
                        <ul>
                            <li>
                                <Link className="nav-link scrollto cursor-pointer" activeClass={"active"} smooth spy
                                      to="hero" offset={-200} duration={200}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link scrollto cursor-pointer" activeClass={"active"} smooth spy
                                      to="about" offset={-69} duration={200}>
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link scrollto cursor-pointer" activeClass={"active"} smooth spy
                                      to="services" offset={-69} duration={200}>
                                    Services
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link scrollto cursor-pointer" activeClass={"active"} smooth spy
                                      to="portfolio" offset={-69} duration={200}>
                                    Portfolio
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link scrollto cursor-pointer" activeClass={"active"} smooth spy
                                      to="contact" offset={-69} duration={200}>
                                    Contact
                                </Link>
                            </li>
                            {/*
                            <li className="dropdown">
                                <a href="#">
                                    <span>Drop Down</span> <i className="bi bi-chevron-down"/>
                                </a>
                                <ul>
                                    <li>
                                        <a href="#">Drop Down 1</a>
                                    </li>
                                    <li className="dropdown">
                                        <a href="#">
                                            <span>Deep Drop Down</span>{" "}
                                            <i className="bi bi-chevron-right"/>
                                        </a>
                                        <ul>
                                            <li>
                                                <a href="#">Deep Drop Down 1</a>
                                            </li>
                                            <li>
                                                <a href="#">Deep Drop Down 2</a>
                                            </li>
                                            <li>
                                                <a href="#">Deep Drop Down 3</a>
                                            </li>
                                            <li>
                                                <a href="#">Deep Drop Down 4</a>
                                            </li>
                                            <li>
                                                <a href="#">Deep Drop Down 5</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">Drop Down 2</a>
                                    </li>
                                    <li>
                                        <a href="#">Drop Down 3</a>
                                    </li>
                                    <li>
                                        <a href="#">Drop Down 4</a>
                                    </li>
                                </ul>
                            </li>
                            */}
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"/>
                    </nav>
                    {/* .navbar */}
                </div>
            </header>
            {/* End Header */}
        </>

    )
}