export const TestomonialsSection = () => {
    return (
        <>
            {/* ======= Testimonials Section ======= */}
            <section id="testimonials" className="testimonials">
                <div className="container" data-aos="zoom-in">
                    <div
                        className="testimonials-slider swiper"
                        data-aos="fade-up"
                        data-aos-delay={100}
                    >
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <img
                                        src="/img/testimonials/testimonials-1.jpg"
                                        className="testimonial-img"
                                        alt=""
                                    />
                                    <h3>Sabbir Khan</h3>
                                    <h4>CEO of Toms Grill</h4>
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"/>
                                        We were struggling with a buggy and outdated website until we contacted Rocketize
                                        Web. They revamped our entire site, making it much more sleek. Their
                                        ongoing support has been invaluable. A five-star service!
                                        <i className="bx bxs-quote-alt-right quote-icon-right"/>
                                    </p>
                                </div>
                            </div>
                            {/* End testimonial item */}
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <img
                                        src="/img/testimonials/testimonials-2.jpg"
                                        className="testimonial-img"
                                        alt=""
                                    />
                                    <h3>Sara Wilsson</h3>
                                    <h4>Designer</h4>
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"/>
                                        Export tempor illum tamen malis malis eram quae irure esse
                                        labore quem cillum quid cillum eram malis quorum velit fore eram
                                        velit sunt aliqua noster fugiat irure amet legam anim culpa.
                                        <i className="bx bxs-quote-alt-right quote-icon-right"/>
                                    </p>
                                </div>
                            </div>
                            {/* End testimonial item */}
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <img
                                        src="/img/testimonials/testimonials-3.jpg"
                                        className="testimonial-img"
                                        alt=""
                                    />
                                    <h3>Jena Karlis</h3>
                                    <h4>Store Owner</h4>
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"/>
                                        Enim nisi quem export duis labore cillum quae magna enim sint
                                        quorum nulla quem veniam duis minim tempor labore quem eram duis
                                        noster aute amet eram fore quis sint minim.
                                        <i className="bx bxs-quote-alt-right quote-icon-right"/>
                                    </p>
                                </div>
                            </div>
                            {/* End testimonial item */}
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <img
                                        src="/img/testimonials/testimonials-4.jpg"
                                        className="testimonial-img"
                                        alt=""
                                    />
                                    <h3>Matt Brandon</h3>
                                    <h4>Freelancer</h4>
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"/>
                                        Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                                        multos export minim fugiat minim velit minim dolor enim duis
                                        veniam ipsum anim magna sunt elit fore quem dolore labore illum
                                        veniam.
                                        <i className="bx bxs-quote-alt-right quote-icon-right"/>
                                    </p>
                                </div>
                            </div>
                            {/* End testimonial item */}
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <img
                                        src="/img/testimonials/testimonials-5.jpg"
                                        className="testimonial-img"
                                        alt=""
                                    />
                                    <h3>John Larson</h3>
                                    <h4>Entrepreneur</h4>
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"/>
                                        Quis quorum aliqua sint quem legam fore sunt eram irure aliqua
                                        veniam tempor noster veniam enim culpa labore duis sunt culpa
                                        nulla illum cillum fugiat legam esse veniam culpa fore nisi
                                        cillum quid.
                                        <i className="bx bxs-quote-alt-right quote-icon-right"/>
                                    </p>
                                </div>
                            </div>
                            {/* End testimonial item */}
                        </div>
                        <div className="swiper-pagination"/>
                    </div>
                </div>
            </section>
            {/* End Testimonials Section */}
        </>

    )
}