import React from 'react';
import './App.css';
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import {ContactSection} from "./components/ContactSection";
import {Footer} from "./components/Footer";
import {TopBar} from "./components/TopBar";
import {Header} from "./components/Header";
import {TeamProvider} from "./providers/TeamProvider";

function App() {
    return (
        <>
            <TeamProvider>
                <TopBar/>
                <Header/>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                    </Routes>
                </BrowserRouter>
                <ContactSection/>
                <Footer/>
            </TeamProvider>
        </>
    );
}

export default App;
