export const FeaturedServicesSection = () => {
    return (
        <>
            {/* ======= Featured Services Section ======= */}
            <section id="featured-services" className="featured-services">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                                <div className="icon">
                                    <i className="bx bxl-dribbble" />
                                </div>
                                <h4 className="title">
                                    <a href="">Web design</a>
                                </h4>
                                <p className="description">
                                    Creating visually appealing and user-friendly website layouts.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
                                <div className="icon">
                                    <i className="bx bx-file" />
                                </div>
                                <h4 className="title">
                                    <a href="">Web development</a>
                                </h4>
                                <p className="description">
                                    Building functional, efficient websites and web applications.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
                                <div className="icon">
                                    <i className="bx bx-tachometer" />
                                </div>
                                <h4 className="title">
                                    <a href="">SEO</a>
                                </h4>
                                <p className="description">
                                    Enhancing website visibility and ranking on search engines.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay={400}>
                                <div className="icon">
                                    <i className="bx bx-world" />
                                </div>
                                <h4 className="title">
                                    <a href="">Marketing</a>
                                </h4>
                                <p className="description">
                                    Promoting products or services to increase brand awareness and sales.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Featured Services Section */}
        </>

    )
}