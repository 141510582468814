import { MainButton } from "../../../components/MainButton";
import { MainCTA } from "../../../components/MainCTA";

export const AboutSection = () => {
    return (
        <section id="about" className="about section-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>About</h2>
                    <h3>
                        Find Out More <span>About Us</span>
                    </h3>
                    <p>
                        Rocketize Web specializes in web design and development.
                        Our team of experrts is dedicated to creating custom
                        solutions that not only looks great but also performs.
                    </p>
                </div>
                <div className="row">
                    <div
                        className="col-lg-6"
                        data-aos="fade-right"
                        data-aos-delay={100}
                    >
                        <img
                            src="/img/about.webp"
                            className="img-fluid h-[600px] mx-auto"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
                        data-aos="fade-up"
                        data-aos-delay={100}
                    >
                        <h3>Transforming Ideas into Reality</h3>
                        <p className="fst-italic">
                            We turn your ideas into stunning, high-performing
                            websites. Our process is seamless, ensuring your
                            vision is brought to life with precision and
                            creativity.
                        </p>
                        <ul>
                            <li>
                                <i className="bx bx-store-alt" />
                                <div>
                                    <h5>Tailored Solutions</h5>
                                    <p>
                                        Every project is unique. We provide
                                        personalized solutions to meet your
                                        specific needs and objectives.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <i className="bx bx-images" />
                                <div>
                                    <h5>Expertise You Can Trust</h5>
                                    <p>
                                        With years of experience, our team
                                        delivers top-quality work that stands
                                        out.
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <p>
                            Our mission is to exceed your expectations with
                            websites that not only impress but also function
                            flawlessly. Partner with us to bring your digital
                            vision to life.
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-center mt-8">
                <MainCTA />
            </div>
        </section>
    );
};
