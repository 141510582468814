import {MainButton} from "./MainButton";
import {useEffect, useState} from "react";

export const MainCTA = () => {
    const [variant, setVariant] = useState<string | undefined>(undefined);

    useEffect(() => {
        setVariant("Contact us")
        // get in touch
        // get started
    }, []);

    return (
        <>
            <MainButton href={"contact"}>{variant}</MainButton>
        </>
    )
}