import {HeroSection} from "./sections/HeroSection";
import {FeaturedServicesSection} from "./sections/FeaturedServicesSection";
import {AboutSection} from "./sections/AboutSection";
import {ClientsSection} from "./sections/ClientsSection";
import {ServicesSection} from "./sections/ServicesSection";
import {TestomonialsSection} from "./sections/TestomonialsSection";
import {PortfolioSection} from "./sections/PortfolioSection";
import {TeamSection} from "./sections/TeamSection";
import {PricingSection} from "./sections/PricingSection";
import {FAQSection} from "./sections/FAQSection";
import {useState} from "react";

const MainPage = () => {
    const [enablePricing, setEnablePricing] = useState<boolean>(false)
    const [teamMode, setTeamMode] = useState<boolean>(false)

    return (
        <>
            <HeroSection/>
            <FeaturedServicesSection/>
            <AboutSection/>
            {/* <SkillsSection/> */}
            {/* <CountsSection/> */}
            <ServicesSection/>
            <ClientsSection/>
            <TestomonialsSection/>
            <PortfolioSection/>
            {teamMode && (
                <TeamSection/>
            )}
            {enablePricing && (
                <PricingSection/>
            )}
            <FAQSection/>
        </>
    )
}

export default MainPage;