export const PortfolioSection = () => {
    return (
        <>
            {/* ======= Portfolio Section ======= */}
            <section id="portfolio" className="portfolio">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Portfolio</h2>
                        <h3>
                            Check our <span>Portfolio</span>
                        </h3>
                        <p>
                            Explore some of our latest projects, crafted to elevate brands and their digital digital presence.
                        </p>
                    </div>
                    {/*
                    <div className="row" data-aos="fade-up" data-aos-delay={100}>
                        <div className="col-lg-12 d-flex justify-content-center">
                            <ul id="portfolio-flters">
                                <li data-filter="*" className="filter-active">
                                    All
                                </li>
                                <li data-filter=".filter-app">App</li>
                                <li data-filter=".filter-web">Web</li>
                            </ul>
                        </div>
                    </div>
                    */}
                    <div
                        className="row portfolio-container"
                        data-aos="fade-up"
                        data-aos-delay={200}
                    >
                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <img
                                src="/img/portfolio/portfolio-1.webp"
                                className="img-fluid"
                                alt=""
                            />
                            <div className="portfolio-info">
                                <h4>App 1</h4>
                                <p>App</p>
                                <a
                                    href="/img/portfolio/portfolio-1.webp"
                                    data-gallery="portfolioGallery"
                                    className="portfolio-lightbox preview-link"
                                    title="App 1"
                                >
                                    <i className="bx bx-plus" />
                                </a>
                                <a
                                    href="portfolio-details.html"
                                    className="details-link"
                                    title="More Details"
                                >
                                    <i className="bx bx-link" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <img
                                src="/img/portfolio/portfolio-2.webp"
                                className="img-fluid"
                                alt=""
                            />
                            <div className="portfolio-info">
                                <h4>Web 3</h4>
                                <p>Web</p>
                                <a
                                    href="/img/portfolio/portfolio-2.webp"
                                    data-gallery="portfolioGallery"
                                    className="portfolio-lightbox preview-link"
                                    title="Web 3"
                                >
                                    <i className="bx bx-plus" />
                                </a>
                                <a
                                    href="portfolio-details.html"
                                    className="details-link"
                                    title="More Details"
                                >
                                    <i className="bx bx-link" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <img
                                src="/img/portfolio/portfolio-3.webp"
                                className="img-fluid"
                                alt=""
                            />
                            <div className="portfolio-info">
                                <h4>App 2</h4>
                                <p>App</p>
                                <a
                                    href="/img/portfolio/portfolio-3.webp"
                                    data-gallery="portfolioGallery"
                                    className="portfolio-lightbox preview-link"
                                    title="App 2"
                                >
                                    <i className="bx bx-plus" />
                                </a>
                                <a
                                    href="portfolio-details.html"
                                    className="details-link"
                                    title="More Details"
                                >
                                    <i className="bx bx-link" />
                                </a>
                            </div>
                        </div>
                        {/*
                        <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                            <img
                                src="/img/portfolio/portfolio-5.jpg"
                                className="img-fluid"
                                alt=""
                            />
                            <div className="portfolio-info">
                                <h4>Web 2</h4>
                                <p>Web</p>
                                <a
                                    href="/img/portfolio/portfolio-5.jpg"
                                    data-gallery="portfolioGallery"
                                    className="portfolio-lightbox preview-link"
                                    title="Web 2"
                                >
                                    <i className="bx bx-plus" />
                                </a>
                                <a
                                    href="portfolio-details.html"
                                    className="details-link"
                                    title="More Details"
                                >
                                    <i className="bx bx-link" />
                                </a>
                            </div>
                        </div>
                        */}
                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <img
                                src="/img/portfolio/portfolio-6.webp"
                                className="img-fluid"
                                alt=""
                            />
                            <div className="portfolio-info">
                                <h4>App 3</h4>
                                <p>App</p>
                                <a
                                    href="/img/portfolio/portfolio-6.webp"
                                    data-gallery="portfolioGallery"
                                    className="portfolio-lightbox preview-link"
                                    title="App 3"
                                >
                                    <i className="bx bx-plus" />
                                </a>
                                <a
                                    href="portfolio-details.html"
                                    className="details-link"
                                    title="More Details"
                                >
                                    <i className="bx bx-link" />
                                </a>
                            </div>
                        </div>
                        {/*
                        <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                            <img
                                src="/img/portfolio/portfolio-9.jpg"
                                className="img-fluid"
                                alt=""
                            />
                            <div className="portfolio-info">
                                <h4>Web 3</h4>
                                <p>Web</p>
                                <a
                                    href="/img/portfolio/portfolio-9.jpg"
                                    data-gallery="portfolioGallery"
                                    className="portfolio-lightbox preview-link"
                                    title="Web 3"
                                >
                                    <i className="bx bx-plus" />
                                </a>
                                <a
                                    href="portfolio-details.html"
                                    className="details-link"
                                    title="More Details"
                                >
                                    <i className="bx bx-link" />
                                </a>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </section>
            {/* End Portfolio Section */}
        </>

    )
}