import {MainCTA} from "../../../components/MainCTA";
import {useTeams} from "../../../providers/TeamProvider";

export const HeroSection = () => {
    const {teamMode} = useTeams()
    return (
        <>
            {/* ======= HeroSection Section ======= */}
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay={100}>
                    {teamMode ? (
                        <>
                            <h1>
                                We are <span>Rocketize Web</span>
                            </h1>
                            <h2>A team of talented web developers making websites that convert customers</h2>
                        </>
                    ) : (
                        <>
                            <h1>
                                Let's <span>🚀 Rocketize</span> your business
                            </h1>
                            <h2>We create websites that convert visitors into customers.</h2>
                        </>
                    )}


                    <div className="d-flex">
                        <MainCTA/>
                        {/*
                        <a
                            href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
                            className="glightbox btn-watch-video">
                            <i className="bi bi-play-circle" />
                            <span>Watch Video</span>
                        </a>
                        */}
                    </div>
                </div>
            </section>
            {/* End HeroSection */}
        </>

    )
}