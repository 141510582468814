import {MainCTA} from "../../../components/MainCTA";

export const ServicesSection = () => {
    return (
        <>
            {/* ======= Services Section ======= */}
            <section id="services" className="services">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Services</h2>
                        <h3>
                            Check our <span>Services</span>
                        </h3>
                        <p>
                            Here are some of the services we've provided to enhance our clients websites and their conversion rates.
                        </p>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-4 col-md-6 d-flex align-items-stretch"
                            data-aos="zoom-in"
                            data-aos-delay={100}
                        >
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bxl-dribbble"/>
                                </div>
                                <h4>
                                    <a href="">Web Design</a>
                                </h4>
                                <p>
                                    Creating visually compelling and intuitive websites for optimal user experience.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                            data-aos="zoom-in"
                            data-aos-delay={200}
                        >
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-file"/>
                                </div>
                                <h4>
                                    <a href="">Web Development</a>
                                </h4>
                                <p>
                                    Developing robust and scalable websites that increase customer conversion.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                            data-aos="zoom-in"
                            data-aos-delay={300}
                        >
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-tachometer"/>
                                </div>
                                <h4>
                                    <a href="">SEO</a>
                                </h4>
                                <p>
                                    Enhancing website visibility and ranking through targeted search engine optimization.                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                            data-aos="zoom-in"
                            data-aos-delay={100}
                        >
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-world"/>
                                </div>
                                <h4>
                                    <a href="">Marketing</a>
                                </h4>
                                <p>
                                    Utilizing Facebook ads to boost engagement and drive targeted traffic.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                            data-aos="zoom-in"
                            data-aos-delay={200}
                        >
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-slideshow"/>
                                </div>
                                <h4>
                                    <a href="">Analytics</a>
                                </h4>
                                <p>
                                    Gathering and analyzing data to gain insights and improve web performance.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                            data-aos="zoom-in"
                            data-aos-delay={300}
                        >
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-arch"/>
                                </div>
                                <h4>
                                    <a href="">A/B Testing</a>
                                </h4>
                                <p>
                                    Conducting comparative tests to determine optimal website elements for user engagement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex mt-8 justify-center"}>
                    <MainCTA/>
                </div>
            </section>
            {/* End Services Section */}
        </>

    )
}