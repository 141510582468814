interface CollapsableProps {
    id: string
    label: string
    children?: React.ReactNode;
}
export const Collapsable = ({id, children, label}:CollapsableProps) => {
    return (
        <>
            <h2 id={`accordion-collapse-heading-${id}`}>
                <button type="button"
                        className="flex items-center gap-2 w-full p-3 font-normal text-gray-800 text-lg border-b-[1px] border-[#d4e5fc] rounded-t-xl hover:text-main transition-all"
                        data-accordion-target={`#accordion-collapse-body-${id}`} aria-expanded="true"
                        aria-controls={`accordion-collapse-body-${id}`}>
                    <svg data-accordion-icon={true} className="w-3 h-3 rotate-180 shrink-0"
                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="2" d="M9 5 5 1 1 5"/>
                    </svg>
                    <span>{label}</span>
                </button>
            </h2>
            <div id={`accordion-collapse-body-${id}`} className="hidden"
                 aria-labelledby={`accordion-collapse-heading-${id}`}>
                <div
                    className="pb-4 pt-2 px-2 border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                    {children}
                </div>
            </div>
        </>
    )
}