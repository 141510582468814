import React, {createContext, useContext} from "react";
export interface TeamHolder {
    teamMode: boolean
}

export const TeamContext = createContext<TeamHolder>({teamMode: false})

interface TeamProviderProps {
    children?: React.ReactNode;
}

export const useTeams = () => {
    const context = useContext(TeamContext);
    if (context === undefined) {
        throw new Error("useTeams() must be used within the TeamProvider");
    }
    return context
};

export const TeamProvider = ({children}:TeamProviderProps) => {
    return (
        <TeamContext.Provider value={{teamMode: false}}>
            {children}
        </TeamContext.Provider>
    )
}