import {useState} from "react";
import {MainButton} from "../../../components/MainButton";

export const PricingSection = () => {
    const [includeStrikeouts, setIncludeStrikeouts] = useState(true)

    return (
        <>
            {/* ======= Pricing Section ======= */}
            <section id="pricing" className="pricing">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Pricing</h2>
                        <h3>
                            Check our <span>Pricing</span>
                        </h3>
                        <p>
                            Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
                            adipisci expedita at voluptas atque vitae autem.
                        </p>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-4 col-md-4"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <div className="box">
                                <h3>Basic</h3>
                                <h4>
                                    <sup>$</sup>690
                                </h4>
                                <ul>
                                    <li>Website Design</li>
                                    <li>Website Development</li>
                                    <li>1 Year Hosting Included</li>
                                    <li>Domain Included</li>
                                    {includeStrikeouts && (
                                        <>
                                            <li className={"na"}>Search Engine Optimization</li>
                                            <li className={"na"}>Google Analytics Tool</li>
                                            <li className={"na"}>Facebook Pixel Integration</li>
                                            <li className={"na"}>Priority Support</li>
                                        </>
                                    )}
                                </ul>
                                <div className="btn-wrap">
                                    <MainButton href={"/#contact"}>
                                    Book a meeting
                                    </MainButton>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-4 mt-4 mt-md-0"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="box featured">
                                <h3>Advanced</h3>
                                <h4>
                                    <sup>$</sup>990
                                </h4>
                                <ul>
                                    <li>Website Design</li>
                                    <li>Website Development</li>
                                    <li>1 Year Hosting Included</li>
                                    <li>Domain Included</li>
                                    <li>Search Engine Optimization</li>
                                    <li>Google Analytics Tool</li>
                                    <li>Facebook Pixel Integration</li>
                                    <li>Priority Support</li>
                                </ul>
                                <div className="btn-wrap">
                                    <MainButton href={"/#contact"}>
                                        Book a meeting
                                    </MainButton>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-4 mt-4 mt-lg-0"
                            data-aos="fade-up"
                            data-aos-delay={300}
                        >
                            <div className="box">
                                <h3>Custom package</h3>
                                <h4>
                                    Let's talk
                                </h4>
                                <ul>
                                    <li>Custom Website Design</li>
                                    <li>Custom Website Development</li>
                                    <li>1 Year Hosting Included</li>
                                    <li>Domain Included</li>
                                    <li>Search Engine Optimization</li>
                                    <li>Google Analytics Tool</li>
                                    <li>Facebook Pixel Integration</li>
                                    <li>Priority Support</li>
                                </ul>
                                <div className="btn-wrap">
                                    <MainButton href={"/#contact"}>
                                        Book a meeting
                                    </MainButton>
                                </div>
                            </div>
                        </div>
                        {/*
                        <div
                            className="col-lg-3 col-md-6 mt-4 mt-lg-0"
                            data-aos="fade-up"
                            data-aos-delay={400}
                        >
                            <div className="box">
                                <span className="advanced">Extra</span>
                                <h3>Marketing</h3>
                                <h4>
                                    Let's talk
                                </h4>
                                <ul>
                                    <li>Website Design</li>
                                    <li>Website Development</li>
                                    <li>1 Year Hosting</li>
                                    <li>Free Domain</li>
                                </ul>
                                <div className="btn-wrap">
                                    <a href="#" className="btn-buy">
                                        Contact us
                                    </a>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </section>
            {/* End Pricing Section */}
        </>

    )
}